var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "800px"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.".concat(_vm.state.isEditMode ? 'edit' : 'add', "Position.title"))) + " ")]), _vm.state.isEditMode && _vm.positionToEdit ? _c('span', {
    staticClass: "mr-4"
  }, [_vm._v(" " + _vm._s(_vm.positionToEdit.createdBy) + " ")]) : _vm._e(), _vm.state.isEditMode && _vm.positionToEdit ? _c('span', [_vm._v(_vm._s(_vm.functions.dateDashNotationToDot(_vm.positionToEdit.createdAt)))]) : _vm._e()]), _c('v-card-text', [_c('PositionForm', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode
    },
    on: {
      "setFormfields": function setFormfields($event) {
        _vm.state.formFields = $event;
      },
      "validationChange": function validationChange($event) {
        _vm.state.isFormValid = $event;
      }
    },
    model: {
      value: _vm.state.form,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "form", $$v);
      },
      expression: "state.form"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": "",
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "disabled": !_vm.state.isFormValid,
      "elevation": "0",
      "loading": _vm.state.isLoadingPositions,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.state.isEditMode ? _vm.listeners.onEdit() : _vm.listeners.onAdd();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("form.".concat(_vm.state.isEditMode ? 'save' : 'add'))) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }