
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash-es'

import PositionForm from '@/views/planning/views/workforcePlanning/components/PositionForm.vue'

import { useNotify } from '@/store'

import { handleError } from '@/utils/handleError'
import { FormField } from '@/utils/types/formField'
import {
  dateDashNotationToDot,
  dateDashNotationToDotWithForm,
  dateDotNotationToDashWithForm,
} from '@/utils/convertDate'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { convertCommaIntoDotInputWithForm, convertDotIntoCommaInputWithForm } from '@/utils/convertInput'
import { isOfType } from '@/utils/types/isOfType'

import { useCreateApproval, useCreatePosition, useDeleteApproval, useUpdatePosition } from '@/api/position'

import {
  ApprovalInput,
  ApprovalOutput,
  PositionInput,
  PositionOutput,
  PostionOutputForApprovalEdit,
} from '@/api/types/position'
import { BasicEntity } from '@/api/types/misc'

export default defineComponent({
  name: 'AddEditPositionDialog',
  components: {
    PositionForm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    positionToEdit: {
      type: Object as PropType<PositionOutput | null>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const isEditMode = computed(() => Boolean(props.positionToEdit))

    const isFormValid = ref(true)

    const formFields = ref<FormField[]>([] as FormField[])

    const existingApprovalPersonIds = ref(
      cloneDeep(props.positionToEdit?.approvals?.map((approval) => approval.approvedBy.id)) ?? []
    )

    const { addNotification } = useNotify()

    const form = ref<PositionInput | PositionOutput | PostionOutputForApprovalEdit>(
      props.positionToEdit ? cloneDeep(props.positionToEdit) : ({} as PositionInput)
    )

    const { createPosition, isLoading: isLoadingCreatePosition } = useCreatePosition()

    async function onAdd(): Promise<void> {
      if (!isOfType<PositionOutput | PostionOutputForApprovalEdit>(form.value, 'id')) {
        let updatedForm: PositionInput = mapBasicEntityToIdWithForm<PositionInput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(formFields.value, updatedForm)
        updatedForm = convertCommaIntoDotInputWithForm(formFields.value, updatedForm)

        updatedForm.approvals =
          updatedForm.approvals?.map((approval) => {
            const newApproval = {
              approvedBy: Number(approval),
              approvedOn: new Date().toISOString().split('T')[0],
            }

            return newApproval
          }) ?? []

        try {
          await createPosition(updatedForm)

          close()

          addNotification({
            text: root.$t('planning.workforcePlanning.create.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updatePosition, isLoading: isLoadingUpdatePositions } = useUpdatePosition()

    const { createApproval } = useCreateApproval()
    const { deleteApproval } = useDeleteApproval()

    async function onEdit(): Promise<void> {
      if (isOfType<PositionOutput | PostionOutputForApprovalEdit>(form.value, 'id')) {
        let updatedForm: PositionOutput = mapBasicEntityToIdWithForm<PositionOutput>(form.value as PositionOutput)

        updatedForm = dateDotNotationToDashWithForm(formFields.value, updatedForm)
        updatedForm = convertCommaIntoDotInputWithForm(formFields.value, updatedForm)

        const updatedFormApprovalPersonIds = form.value.approvals as number[]

        // Approvals are updated and deleted with approvals endpoint and not in the position
        updatedForm.approvals = []

        // Update approvals
        if (!isOfType<BasicEntity>(updatedFormApprovalPersonIds[0], 'id')) {
          const approvalsToUpdatePromises = updatedFormApprovalPersonIds
            ?.filter(
              (newApprovalPersonId) =>
                !existingApprovalPersonIds.value.some(
                  (existingApprovalPersonId: number) => existingApprovalPersonId === newApprovalPersonId
                )
            )
            .map((approvalPersonId) => {
              const approval: ApprovalInput = {
                approvedBy: approvalPersonId,
                approvedOn: new Date().toISOString().split('T')[0],
              }

              return createApproval(updatedForm.id, approval)
            })

          await Promise.all(approvalsToUpdatePromises ?? [])

          //Delete approvals
          const approvalsToDeletePromises = existingApprovalPersonIds.value
            ?.filter(
              (existingApprovalPersonId) =>
                !updatedFormApprovalPersonIds.some(
                  (newApprovalPersonId: number) => newApprovalPersonId === existingApprovalPersonId
                )
            )
            .map((approvalPersonId) => {
              const existingApprovals = props.positionToEdit?.approvals ?? []

              const approvalIdToDelete = existingApprovals.find(
                (approval) => approval.approvedBy.id === approvalPersonId
              )?.id

              if (!approvalIdToDelete) return

              return deleteApproval(updatedForm.id, approvalIdToDelete)
            })

          await Promise.all(approvalsToDeletePromises ?? [])
        }

        try {
          let cloneOfPositionToEdit = mapBasicEntityToIdWithForm(cloneDeep(props.positionToEdit))

          if (
            !isEqual(
              { ...cloneOfPositionToEdit, approvals: null, commitMessage: null },
              { ...updatedForm, approvals: null, commitMessage: null }
            )
          ) {
            await updatePosition(updatedForm.id, updatedForm)
          }

          addNotification({
            text: root.$t('planning.workforcePlanning.edit.message') as string,
            type: 'success',
          })
        } catch (error: unknown) {
          handleError(error)
        }
        close()
      }
    }

    const isLoadingPositions = computed(() => isLoadingUpdatePositions.value || isLoadingCreatePosition.value)

    function close(): void {
      emit('added-edited')

      emit('close')
    }

    watch(
      () => formFields.value,
      () => {
        if (props.positionToEdit && isOfType<PositionInput>(form.value, 'id')) {
          form.value = cloneDeep({
            ...props.positionToEdit,
            approvals: convertApprovals(props.positionToEdit.approvals),
          })

          form.value = dateDashNotationToDotWithForm(formFields.value, form.value)
          form.value = convertDotIntoCommaInputWithForm(formFields.value, form.value)
        }
      }
    )

    function convertApprovals(approvalsToConvert: ApprovalOutput[] | null): BasicEntity[] {
      if (!approvalsToConvert) return []

      return approvalsToConvert.map((approval) => approval.approvedBy)
    }

    return reactive({
      state: {
        formFields,
        isEditMode,

        form,
        isFormValid,

        isLoadingPositions,
      },
      functions: {
        dateDashNotationToDot,
      },
      listeners: {
        onAdd,

        onEdit,
      },
    })
  },
})
